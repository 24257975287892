import React, { useState } from 'react';
import { compose } from '../../core';
import { getIntFromString, withMarkdownFieldValue } from '../../look';
import ManagerAddCommitmentView, { CKEditorFieldName } from '../components/ManagerAddCommitmentView';
import { withAddCustomizedCoachingCommitment, withMyCustomizedCoachingCommitments } from './ManagerOperations';
import { withMe } from '../../user/containers/UserOperations';
import { withGetCommitmentRecommendations } from '../../tool/containers/ToolOperations';
import { withAddSelectDefaultToolCommitment } from '../../dashboard/containers/DashboardOperations';

const ManagerAddCommitment = props => {
  const { addCustomizedCoachingCommitment, me, modal2, setModal2, commitment, getCommitmentRecommendations ,addSelectDefaultToolCommitment} = props;
  const [ description, setDescription ] = useState()
  const [title, setTitle] = useState()
  const [dueDate, setDueDate] = useState()
  
  console.log('notif4r4', getCommitmentRecommendations)

  const handleSelectDefaultToolCommitment = async (commitmentId) => {
    try {
      const defaultCommitmentProgressData = {
        userId: me && getIntFromString(me.id),
        commitmentId,
      };
      await addSelectDefaultToolCommitment({ defaultCommitmentProgressData });
    } catch (err) {
      throw new Error(err.message);
    }
  };

  const handleSubmit = async values => {
    if(dueDate){
      try {
        let data = {
          ...withMarkdownFieldValue(CKEditorFieldName, values),
          forUserId: getIntFromString(me.id),
        };
        if (modal2.default) {
          data.coachingCommitmentData.commitmentId = modal2.id;
        } else {
          data.coachingCommitmentData.customusercommitmentId = modal2.id;
        }
        data.coachingCommitmentData.description = description
        data.coachingCommitmentData.title = title
        data.coachingCommitmentData.dueDate = dueDate
  
  
        console.log('oldrt4t44t4t:: ',data, description);
        const response = await addCustomizedCoachingCommitment(data);
        if (response) {
          setModal2({ visible: false, id: 0, default: false });
        }
      } catch (e) {
        throw new Error(e.message);
      }
    } else {
      alert('Please provide a valid Due Date')
    }
  };
return <ManagerAddCommitmentView setDueDate={setDueDate} handleSelectDefaultToolCommitment={handleSelectDefaultToolCommitment} recoCommitments={getCommitmentRecommendations} setTitle={setTitle} commitment={commitment} setDescription={setDescription} {...props} onSubmit={handleSubmit} />;
};

export default compose(
  withMe,
  withMyCustomizedCoachingCommitments,
  withAddCustomizedCoachingCommitment,
  withGetCommitmentRecommendations,
  withAddSelectDefaultToolCommitment
)(ManagerAddCommitment);
