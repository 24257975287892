import React, { useState } from 'react';
import { Col, Card, Modal, Row, Progress, Form } from 'antd';

import { compose } from '../../core';
import { getStrokeColor } from '../../look';
import { withMe } from '../../user/containers/UserOperations';

import TeamsResiliencePulseCheck from '../components/pulse-check/TeamsResiliencePulseCheck';
import RenderMyTeamsResiliencePulseCheck from '../components/pulse-check/RenderMyTeamsResiliencePulseCheck';
import RenderMyVerticalResiliencePulseCheck from '../components/pulse-check/RenderMyVerticalResiliencePulseCheck';
import RenderMyOrganizationResiliencePulseCheck from '../components/pulse-check/RenderMyOrganizationResiliencePulseCheck';

import { withMyResiliencePulseCheck } from './DashboardOperations';

const FormItem = Form.Item;

const ResiliencePulseCheck = props => {
  const { myResiliencePulseCheck, me, personal, manager } = props;
  const [visible, setVisible] = useState(false);

  const isEmployee = me?.employee;
  const isManager = manager && isEmployee;
  return (
    <>
      <Col span={24}>
        <Card
          bodyStyle={{
            boxShadow: '5px 0px 25px 3px rgba(32, 32, 32, 0.1)',
            borderRadius: '10px',
            paddingBottom: 0,
            paddingTop: isManager ? 20 : 0,
            cursor: 'pointer'
          }}
          bordered={true}
          hoverable
          onClick={() => (isManager ? null : setVisible(true))}
        >
          {isManager ? (
            <TeamsResiliencePulseCheck />
          ) : (
            <h2 align="center" style={{ margin: '1em 0', fontWeight: 'normal', color: visible ? '#881FCC' : '#222' }}>
              Resilence Pulse Check
            </h2>
          )}
        </Card>
      </Col>

      <Modal
        footer={null}
        centered
        visible={/* true || */ visible}
        width={'40%'}
        destroyOnClose={true}
        onCancel={() => setVisible(false)}
        // bodyStyle={{ padding: '0px' }}
      >
        <Row>
          <Col span={22}>
            {personal && (
              <>
                <FormItem
                  labelCol={{ span: 24 }}
                  wrapperCol={{ span: 24, offset: 1 }}
                  label={'User’s Resilience Pulse Check'}
                >
                  <Progress
                    format={percent => percent/10 + '%'}
                    percent={(myResiliencePulseCheck || 0).toFixed(2) * 10}
                    strokeColor={getStrokeColor(myResiliencePulseCheck || 0)}
                  />
                </FormItem>

                {isEmployee && <RenderMyTeamsResiliencePulseCheck />}
                {isEmployee && <RenderMyVerticalResiliencePulseCheck />}
                {isEmployee && <RenderMyOrganizationResiliencePulseCheck />}
              </>
            )}

            {manager && isEmployee && <TeamsResiliencePulseCheck />}
          </Col>
        </Row>
      </Modal>
    </>
  );
};

export default compose(withMe, withMyResiliencePulseCheck)(ResiliencePulseCheck);
