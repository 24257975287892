import React, { useEffect, useState, useContext } from 'react';
import { Progress, Spin } from 'antd';
import gql from 'graphql-tag';
import { graphql } from '@apollo/client/react/hoc';
import '../css/CoachingCommitmentProgressBar.css'

import { compose } from '../../core';
import { Contextual } from '../../dashboard/components/CommitmentView';

const GET_MY_COACHING_COMMITMENTS = gql`
  query getMyCoachingCommitmentsProgress($completed: Boolean) {
    getMyCoachingCommitmentsProgress(completed: $completed) {
      totalCount
    }
  }
`;

const CoachingCommitmentProgressBar = props => {
  const { loading, coachingCommitmentsCompleted, coachingCommitments, updated, refetchCompleted, refetchTotal, visible } = props;
  const totalCompleted = coachingCommitmentsCompleted?.totalCount;
  const totalCount = coachingCommitments?.totalCount;
  const progress = React.useRef(() => {});
  const [animate, setAnimate] = useState(false);
  const {defaultCustomUserCommitments} = useContext(Contextual)
  // const [percentage, setPercentage] = useState((totalCompleted / totalCount || 0).toFixed(2))
  // useEffect(() => {
  //   progress.current();
  // }, [updated]);

  console.log('')

  const triggerAnimation = () => {
    setAnimate(true); // Set animate state to true to trigger the animation
    setTimeout(() => {
      setAnimate(false); // Set animate state back to false after the animation duration (1.5s in your CSS)
    }, 1500);
  };

  useEffect(()=>{
    if(visible){
      scrollToElement()
      triggerAnimation()
    }
  },[visible])

  const scrollToElement = () => {
    if (progress.current) {
      progress.current.scrollIntoView({
        behavior: 'smooth', // Optional: Adds smooth scrolling behavior
        block: 'start', // Optional: Scrolls to the top of the element
      });
    }
  };

  console.log('visibhyy98le',totalCompleted,totalCount,)

  return (
    <Spin className='progress_spin' spinning={loading} size="small">
      {/* <Progress
        type="circle"
        percent={((defaultCustomUserCommitments?.edges?.filter(item => item?.node?.completed).length * 100) / totalCount || 0).toFixed(2)}
        strokeColor="#B0CB1F"
        strokeWidth="10"
      /> */}
      <div style={{width:'100%',height:'21em',display:'flex',flexDirection:'column',justifyContent:'flex-start',alignItems:'center',borderBottom:'1px solid gray'}}>
      <div ref={progress} style={{visibility:`${visible ? 'visible': 'hidden'}`}} className='barcontainer'>
        <div className={animate ? 'bar animate' : 'bar'} style={{height:`${animate ? '0%': `${((totalCompleted * 100) / totalCount || 0).toFixed(2)}%`}`, '--final-height': `${((totalCompleted * 100) / totalCount || 0).toFixed(2)}%`,'--inner-color': 'rgba(165, 196, 43, 1)',paddingTop: '0.4em', fontWeight: '600', fontFamily: 'poppins, sans-serif', color: 'white'}}>{`${((totalCompleted * 100) / totalCount || 0).toFixed(2)}%`}</div>
      </div>
      </div>
      <p>1</p>

      
      {/* <button onClick={visible && triggerAnimation}>trigger animation</button> */}
    </Spin>
  );
};

export default compose(
  graphql(GET_MY_COACHING_COMMITMENTS, {
    
    options: () => {
      return { variables: { completed: true } };
    },
    props({ data }) {
      const { loading, error, getMyCoachingCommitmentsProgress, subscribeToMore, updateQuery, refetch } = data;
      return {
        loading,
        error,
        coachingCommitmentsCompleted: getMyCoachingCommitmentsProgress,
        subscribeToMore,
        updateQuery,
        refetchCompleted: refetch
      };
    }
  }),
  graphql(GET_MY_COACHING_COMMITMENTS, {
    props({ data }) {
      const { loading, error, getMyCoachingCommitmentsProgress, subscribeToMore, updateQuery, refetch } = data;
      return {
        loading,
        error,
        coachingCommitments: getMyCoachingCommitmentsProgress,
        subscribeToMore,
        updateQuery,
        refetchTotal: refetch
      };
    }
  })
)(CoachingCommitmentProgressBar);
